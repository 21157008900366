<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">
          {{field == "suspensao_perda_rouboComponent" ? "Suspensão Perda ou Roubo" : "Suspensão de Linha Voluntária"}}
        </v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      md="6"
      sm="12"
      v-for="(lineSelected, index) in lineSelect"
      :key="index"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="primary white--text">
          <div class="headerClass">
            {{lineSelected.line.ddi ? lineSelected.line.ddi+' ('+lineSelected.line.ddd+') '+lineSelected.line.nr_linha : 'Selecione uma linha' }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="lineSelected.line"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id"
                :rules="lineRules"
                :filter="customFilter"
                return-object
                @input="updateSelectedLine($event, lineSelected, index)"
                :disabled="loadingLinePackages[index].value"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <ListPackage
                v-if="loadingLinePackages[index]"
                :loadingPackage="loadingLinePackages[index].value"
                :lineSelected="lineSelected"
            >
            </ListPackage>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import ListPackage from "@/components/package/ListPackage";
export default {
  components: {ListPackage},
  props: ["field","index"],
  data() {
    return {
      lineSelect: [],
      loading: true,
      show: false,
      lineRules: [v => !!v || "Linha é obrigatório"],
      loadingLinePackages: []
    };
  },
  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    await this.clickAddNewLine();
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapMutations({ setModel: "forms/setModelVoluntaryLineSuspension" }),
    async clickAddNewLine() {
      const newLine = {
        packages: [],
        line: {}
      };

      this.loadingLinePackages.push({
        value: false
      });
      this.lineSelect = [...this.lineSelect, newLine];
      this.updateValues();
    },
    async updateSelectedLine(line, lineSelected, index) {
      this.show = this.show ? false : lineSelected;
      this.loadingLinePackages[index].value = true;
      if (line.id_linha.length) {
        lineSelected.packages = [];
        await this.loadLinePackages({
          id_linha: line.id_linha,
          type: "old"
        }).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        lineSelected.packages = this.linePackages.data;
      }
      this.updateValues();
      this.show = false;
      this.loadingLinePackages[index].value = false;
    },
    updateValues() {
      const payload = {
        step: this.index,
        selected: {
          model: {lines: this.lineSelect},
          revision: {lines: this.lineSelect}
        }
      }
      this.setModel(payload);
    },
    closeLine(index) {
      if (index > -1) {
        this.lineSelect.splice(index, 1);
        this.loadingLinePackages.splice(index, 1);
      }
    },
    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
