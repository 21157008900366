<template>
  <v-row>
    <!-- Loading -->
    <v-col v-if="loading">
      <v-skeleton-loader type="card-heading"></v-skeleton-loader>
    </v-col>
    <!-- Componentes de campos -->
    <v-col cols="12" v-if="!loading && !isReview">
      <v-form ref="wizardRef">
      <div v-for="(val, index) in item" @click="confirmEdit()" :key="index">
        <component :class="{ 'disabled-click': isEdited }" :is="val.component" :field="val" :index="index" :groupUser="groupUser" :isGroupPessoa="isGroupPessoa" />
      </div>
      </v-form>
    </v-col>

    <!-- Revisão -->
    <v-col v-if="!loading && isReview">
      <WizardReviewComponent />
    </v-col>

    <v-col
      v-if="this.groupUser === 1"
      cols="12"
      :class="{ 'disabled-click': isReview }"
    >
      <EmailSendProviderComponent
        :create_solicitaion="true"
        @changeMessageEmail="changeMessageEmail($event)"
        ref="emailSendProvider"
      />
    </v-col>

    <!-- Botões -->
    <v-col cols="12" align="right" v-if="!loading">
      <v-btn
        color="outline"
        @click="isReview = false"
        :disabled="!isReview"
        class="mx-5"
        >Voltar</v-btn
      >
      <v-btn
        color="primary"
        :loading="btn_loading"
        :disabled="btn_loading"
        @click="nextStep()"
        >{{ isReview ? 'Salvar' : 'Revisar Solicitação' }}</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import router from "@/router";
import {
  RequesterComponent,
  PlansComponent,
  personComponent,
  SelectListComponent,
  TextComponent,
  TextareaComponent,
  imeiComponent,
  dddComponent,
  companyComponent,
  ProviderComponent,
  DateComponent,
  NumberComponent,
  PackagesComponent,
  LinhaComponent,
  troca_dddComponent,
  change_number_undue_divulgationComponent,
  line_disable_packageComponent,
  RoamingComponent,
  cancel_lineComponent,
  change_chipComponent,
  suspend_lineComponent,
  reactivate_lineComponent,
  voluntary_line_suspensionComponent,
  suspensao_perda_rouboComponent,
  update_golComponent,
  activate_new_lineComponent,
  CreatedByPhoneComponent
} from "./forms";
import WizardReviewComponent from "@/components/wizardReview/WizardReviewComponent";
import EmailSendProviderComponent from "./emailConfig/EmailSendProviderComponent.vue";

export default {
  components: {
    EmailSendProviderComponent,
    RequesterComponent,
    PlansComponent,
    personComponent,
    SelectListComponent,
    TextComponent,
    TextareaComponent,
    imeiComponent,
    dddComponent,
    companyComponent,
    ProviderComponent,
    DateComponent,
    NumberComponent,
    PackagesComponent,
    LinhaComponent,
    troca_dddComponent,
    change_number_undue_divulgationComponent,
    line_disable_packageComponent,
    RoamingComponent,
    WizardReviewComponent,
    cancel_lineComponent,
    change_chipComponent,
    reactivate_lineComponent,
    suspend_lineComponent,
    voluntary_line_suspensionComponent,
    suspensao_perda_rouboComponent,
    update_golComponent,
    activate_new_lineComponent,
    CreatedByPhoneComponent
  },
  data() {
    return {
      btn_loading: false,
      forms: [],
      loading: true,
      disabled_next: false,
      field_name: "",
      isGroupPessoa: false,
      groupUser: "",
      isReview: false,
      subjectEmail: null,
      messageEmail: null,
      isEdited: false,
    };
  },
  computed: {
    ...mapGetters({
      total: "forms/getTotalSteps",
      step: "forms/getStep",
      item: "forms/getItemComponent",
      model: "forms/getModel",
      currentItem: "forms/getCurrentItem",
      currentValue: "forms/getItemValue",
      errorMessage: "forms/getErrorMessage"
    }),
    ...mapState("forms", ["wizard"]),
    ...mapState("solicitation", ["wizard_data"]),
  },
  async mounted() {
    await this.loadWizard({
      id: this.$route.params.id
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
      this.$router.go(-1);
    });

    if (localStorage.getItem("saveValue")) {
      localStorage.removeItem("saveValue");
    }

    this.clearLocalStorageChangeChip();

    if (this.wizard_data.data.length) {
      this.forms = this.wizard_data.data;
      this.isGroupPessoa = this.forms[0]?.group_pessoa;
      this.groupUser = this.forms[0]?.group_user;
      this.setForms(this.forms);
      this.disabled_next = this.forms[0].required;
      this.loading = false;
      this.setStep(0);
      this.field_name = this.forms[0].name;
    } else {
      await this.saveWizard({
        solicitation_type: this.$route.params.id,
        fields: {}
      });
    }
  },
  watch: {
    wizard: {
      deep: true,
      immediate: true,
      /* A cada interação com o wizard formata email para fornecedor */
      handler: async function (value) {
        if (this.groupUser === 1) {
          this.btn_loading = true;
          const params = {
            solicitation_id: this.$route.params.id,
            values: value.model,
            create_solicitaion: true
          }

          await this.$refs.emailSendProvider.loadFormEmail(params);
          this.btn_loading = false;
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setStep: "forms/setStep",
      setForms: "forms/setForms",
      setErrorMessage: "forms/setErrorMessage"
    }),
    ...mapActions("solicitation", ["loadWizard", "saveSolicitation"]),
    changeMessageEmail(event) {
      this.subjectEmail = event.subjectEmail;
      this.messageEmail = event.messageEmail;
      if (event.customEdit) {
        this.isEdited = true;
      }
    },
    nextStep() {
      let hasError = false;
      if (!this.isReview && !this.$refs.wizardRef.validate()) {
        this.$swal('Oops...', 'Preencha todos os campos obrigatórios.', 'warning');
        return;
      }
      for (let index = 0; index < this.currentItem.length; index++) {
        const val = this.currentItem[index];
        if (val.required && !this.currentValue(index)) {
          if (this.errorMessage.message) {
            this.$swal(
              "Oops...",
              this.errorMessage.message,
              "error"
            );
          } else {
            this.$swal(
              "Oops...",
              `O campo "${val.label}" é obrigatório`,
              "error"
            );
          }
          hasError = true;
          break;
        }
      }

      if (!hasError) {
        if (this.isReview) {
          this.saveWizard({
            solicitation_type: this.$route.params.id,
            fields: this.model,
            subjectEmail: this.subjectEmail,
            messageEmail: this.messageEmail,
            manualEditEmail: this.isEdited
          });
        } else {
          this.isReview = true;
        }
      }
    },
    confirmEdit() {
      if (this.isEdited) {
        this.$swal({
          title: 'Deseja editar a solicitação?',
          text: 'Você perderá as informações adicionais que anexou ao e-mail.',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.isEdited = false;
            this.existEmailForm = false;
          }
        });
      }
    },
    async saveWizard(data) {
      this.btn_loading = true;
      await this.saveSolicitation(data).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.clearLocalStorageChangeChip();
      this.btn_loading = false;
      await this.$router.push({
        name: "solicitation"
      });
    },
    clearLocalStorageChangeChip() {
      if (localStorage.getItem("change_chip_saved_value")) {
        localStorage.removeItem("change_chip_saved_value");
      }
    }
  }
};
</script>
<style scoped>
.disabled-click {
  pointer-events: none;
}
</style>
