<template>
  <v-row class="mx-auto">
    <v-col cols="5">
      <v-autocomplete
        v-model="selected"
        :items="lines.data"
        :label="label(index)"
        item-text="nr_linha"
        item-value="id_linha"
        @change="setValue"
        outlined
        dense
        clearable
        return-object
        :filter="filterLine"
      >
        <template v-slot:selection="data">
          {{ data.item.ddi }} ({{ data.item.ddd }})
          {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
          {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
        </template>

        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.ddi }} ({{ data.item.ddd }})
                {{ data.item.nr_linha }}
              </v-list-item-title>
              <v-list-item-subtitle v-html="data.item.nome_completo"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  props: ['index'],
  data() {
    return {
      selected: {}
    };
  },
  computed: {
    ...mapGetters({
      label: "forms/getItemLabel",
      options: "forms/getItemOptions"
    }),
    ...mapState("packages", ["lines"])
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModelLinha"
    }),
    ...mapActions("packages", ["loadLines"]),
    setValue() {
      const payload = {
        step: this.index,
        selected: this.selected
      }
      this.setModel(payload);
    },
    filterLine: function (item, queryText) {
      queryText = queryText.replace(/[\s()\-/]/g, '');
      const regExp = new RegExp(queryText, 'i')
      const phoneNumber = item.ddi + item.ddd + item.nr_linha;
      return (
          regExp.test(phoneNumber) || regExp.test(item.nome_completo.replace(/\s/g, ''))
      );
    },
  },
  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
  },
};
</script>
<style scoped></style>
