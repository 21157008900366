<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{ dynamicLabel }}</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      :cols="updateManagerLine.length > 1 ? 6 : 12"
      v-for="(updateLine, index) in updateManagerLine"
      :key="index"
    >
      <v-card outlined class="mx-auto">
        <v-card-title class="ma-0 pa-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLine(index)" class="mx-3 mt-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                dense
                outlined
                required
                label="Linha"
                v-model="updateLine.id"
                :items="lines.data"
                item-text="nr_linha"
                item-value="id_linha"
                :rules="lineRules"
                :filter="customFilter"
                @change="setState()"
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-select
                :items="situationList"
                item-text="name"
                item-value="value"
                v-model="updateLine.fields[0].value"
                label="Perfil de Bloqueio"
                outlined
                dense
                :disabled="updateLine.id ? false : true"
                @change="checkValues(updateLine.fields[0].value, index)"
              ></v-select>
              <v-container
                class="px-0"
                fluid
                dense
                v-for="(option, switchIndex) in typeList"
                :key="switchIndex"
              >
                <v-checkbox
                  v-model="updateLine.fields[1].values"
                  :label="option.name"
                  :value="option.value"
                  :disabled="updateLine.fields[0].value ? false : true"
                  :readonly="updateLine.fields[0].value == 2 ? true : false"
                  class="mt-0"
                  dense
                  @change="setState()"
                ></v-checkbox>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      updateManagerLine: [],
      dynamicLabel: "",
      loading: true,
      lineRules: [v => !!v || "Linha é obrigatório"]
    };
  },
  async mounted() {
    await this.loadLines().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadblockSituation().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadBlockType().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.wizard.model["update_gol"]) {
      this.wizard.forms.map(form => {
        if (form.type == "update_gol") this.dynamicLabel = form.label;
      });
    }
    this.loading = false;
  },

  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("forms", ["wizard"]),
    ...mapState("onlineManager", ["situationList", "typeList"])
  },

  methods: {
    ...mapActions("packages", ["loadLines"]),
    ...mapActions("onlineManager", ["loadblockSituation", "loadBlockType"]),
    ...mapMutations({
      setModel: "forms/setModelUpdateOnlineManager"
    }),

    async clickAddNewLine() {
      let id = "";

      if(this.lines != null && Array.isArray(this.lines.data) && this.lines.data.length === 1) {
        id = this.lines.data[0].id_linha;
      }

      const newLine = {
        id,
        fields: [
          {
            name: "block_situation",
            type: "block_situation",
            label: "Tipo de Bloqueio",
            value: ""
          },
          {
            name: "block_type",
            type: "block_type",
            label: "",
            values: []
          }
        ]
      };
      this.updateManagerLine.push(newLine);
    },

    checkValues(selectedOption, index) {
      if (selectedOption == 2) {
        this.updateManagerLine[
          index
        ].fields[1].values = this.filterBoxOptions();
      } else {
        this.updateManagerLine[index].fields[1].values = [];
      }
      this.setState();
    },

    filterBoxOptions() {
      let setAllOptions = [];
      this.typeList.map(option => {
        setAllOptions.push(option.value);
      });
      return setAllOptions;
    },

    setState() {
      this.setModel(this.updateManagerLine);
    },

    closeLine(index) {
      if (index > -1) {
        this.updateManagerLine.splice(index, 1);
      }
      this.setState();
    },

    customFilter(item, queryText) {
      const textOne = item.nr_linha.toLowerCase();
      const textTwo = item.nome_completo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
