<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <v-card outlined :loading="loading" :disabled="loading">
        <CustomSelectComponent
          :cols="3"
          :options="optionsSelectCustom"
          @change="changeSelectCustom"
        ></CustomSelectComponent>

        <CustomSelectPackageComponent
          v-if="showPackage"
          :page="'wizard'"
          @updateValues="updateValues"
        >
        </CustomSelectPackageComponent>

        <div v-if="showPlan">
          <v-card-title class="subtitle-1">
            Selecione um ou mais planos
          </v-card-title>
          <v-card-text>
            <v-chip-group column multiple>
              <v-chip
                v-for="plan in plans.data"
                :key="plan.name"
                :value="plan.name"
                @click="clickPlan(plan)"
              >
                {{ plan.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </div>
      </v-card>
    </v-col>
    <div v-if="showPlan" style="display: contents;">
      <v-col md="4" sm="6" v-for="(plan, index) in plansSelect" :key="index">
        <v-card class="mx-auto">
          <v-card-title class="primary white--text">
            <div class="title-content">
              {{ plan.name }}
            </div>
            <v-btn icon class="close-button" @click="closePlan(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="primary white--text">
            <h3>{{ plan.price_format }}</h3>
          </v-card-subtitle>
          <v-card-text>
            <v-row align="center">
              <v-col class="text-center">
                <v-icon x-large>mdi-web</v-icon>
                <div class="title">{{ plan.data }}</div>
                <div class="subtitle-2">Dados</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-phone-outgoing</v-icon>
                <div class="title">{{ plan.voice }}</div>
                <div class="subtitle-2">Chamadas</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-message-text-outline</v-icon>
                <div class="title">{{ plan.sms }}</div>
                <div class="subtitle-2">Mensagens</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  required
                  label="DDD"
                  v-model="plan.code"
                  :items="ddd.data"
                  item-text="code"
                  item-value="code"
                  :rules="dddRules"
                  @change="updateValues()"
                >
                  <template v-slot:selection="data">
                    {{ data.item.code }} - {{ data.item.region }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.region"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  dense
                  outlined
                  required
                  :label="'Selecione a Pessoa'"
                  v-model="plan.person"
                  :items="persons.data"
                  item-text="nome_completo"
                  item-value="id_pessoa"
                  :filter="filterObject"
                  v-if="!loading"
                  @change="updateValues()"
                >
                  <!-- v-if="!loading && field.type === 'new_person_troca_ddd'" -->
                </v-autocomplete>
              </v-col>
              <v-col cols="12" align="center" v-if="plan.description">
                <v-btn text @click="showInfo(plan)" color="primary" small>
                  Mais informações
                  <v-icon>{{
                      show === plan ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show === plan">
              <v-divider></v-divider>

              <v-card-text>
                <div v-html="plan.description"></div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import CustomSelectComponent from "@/components/forms/CustomSelectComponent.vue";
import CustomSelectPackageComponent from "@/components/forms/CustomSelectPackageComponent.vue";
export default {
  components: { CustomSelectPackageComponent, CustomSelectComponent },
  props: ['index'],
  data() {
    return {
      plansSelect: [],
      loading: true,
      show: false,
      showPlan: false,
      showPackage: false,
      packagesSelected: [],
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      optionsSelectCustom: [
        { text: 'Plano', value: 'plan' },
        { text: 'Pacote', value: 'package' }
      ],
      dddRules: [v => !!v || "DDD é obrigatório"],
      linhasRules: [
        v =>
          (v && v >= 0) || "A quantidade de linhas não pode ser menor que zero"
      ]
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.loadPlans(),
        this.loadDDD(),
        this.loadPersons()
      ]);
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }
    this.loading = false;
  },
  computed: {
    ...mapState("plans", ["plans", "ddd"]),
    ...mapState("persons", ["persons"]),
    ...mapGetters({
      name: "forms/getItemName"
    }),
    ...mapState("icon", ["icons"])
  },
  methods: {
    ...mapActions("plans", ["loadPlans", "loadDDD"]),
    ...mapActions("persons", ["loadPersons"]),
    ...mapMutations({
      setModel: "forms/setModelActivateLine"
    }),

    changeSelectCustom(data) {
      if (data.value === 'package') {
        this.showPackage = true;
        this.showPlan = false;
      } else {
        this.showPackage = false;
        this.showPlan = true;
      }
      this.plansSelect = [];
      this.updateValues();
    },

    clickPlan(plan) {
      if(this.persons != null && Array.isArray(this.persons.data) && this.persons.data.length === 1) {
        plan.person = this.persons.data[0];
      }

      const newPlan = {
        code: "",
        data: plan.data,
        data_format: plan.data_format,
        description: plan.description,
        id: plan.id,
        name: plan.name,
        price: plan.price,
        price_format: plan.price_format,
        sms: plan.sms,
        sms_format: plan.sms_format,
        voice: plan.voice,
        voice_format: plan.voice_format,
        person: plan.person
      };
      
      this.plansSelect = [...this.plansSelect, newPlan];
    },

    closePlan(index) {
      if (index > -1) {
        this.plansSelect.splice(index, 1);
      }
    },

    updateValues(packagesSelected) {
      let type = 'plan';
      let data = this.plansSelect;
      if (packagesSelected && packagesSelected.length > 0) {
        type = 'package';
        data = packagesSelected
      }
      this.setModel({
        step: this.index,
        selected: data,
        type: type,
      });
    },

    showInfo(plan) {
      this.show = this.show ? false : plan;
    },

    filterObject(item, queryText) {
      let result = queryText * 1;
      let text = "";
      if (isNaN(result)) {
        text = this.clearText(item.nome_abreviado);
      } else {
        let phone = item.ddi + item.ddd + item.nr_linha;
        text = this.clearText(phone);
      }
      const searchText = this.clearText(queryText);
      result = text.indexOf(searchText) > -1;
      return result;
    },

    clearText(text) {
      let cleanText = text.toLowerCase();
      cleanText = cleanText.replace(new RegExp(/\s/g), " ");
      cleanText = cleanText.replace(new RegExp(/[àáâãäå]/g), "a");
      cleanText = cleanText.replace(new RegExp(/æ/g), "ae");
      cleanText = cleanText.replace(new RegExp(/ç/g), "c");
      cleanText = cleanText.replace(new RegExp(/[èéêë]/g), "e");
      cleanText = cleanText.replace(new RegExp(/[ìíîï]/g), "i");
      cleanText = cleanText.replace(new RegExp(/ñ/g), "n");
      cleanText = cleanText.replace(new RegExp(/[òóôõö]/g), "o");
      cleanText = cleanText.replace(new RegExp(/œ/g), "oe");
      cleanText = cleanText.replace(new RegExp(/[ùúûü]/g), "u");
      cleanText = cleanText.replace(new RegExp(/[ýÿ]/g), "y");
      cleanText = cleanText.replace(new RegExp(/\W/g), " ");
      return cleanText;
    }
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-content {
  white-space: normal;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
