<template>
  <v-row class="mx-auto">
    <v-col cols="12">
      <div v-if="typeComponent === 'package'">
        <v-card :loading="loading" dense outlined class="pb-1 pr-2 pl-2">
          <v-col cols="12">
            <v-card outlined>
              <CustomSelectPackageComponent
                :options="revisionContent"
                :desactiveFields="true"
                :showButtonAddNewCard="false"
              >
              </CustomSelectPackageComponent>
            </v-card>
          </v-col>
        </v-card>
      </div>
    </v-col>
    <div v-if="typeComponent === 'plan'" style="display: contents">
      <v-col
        v-for="(lineSelected, indexRevision) in revisionContent"
        :key="indexRevision"
        cols="4"
      >
        <v-card class="mx-auto">
          <v-card-title class="primary white--text" :title="lineSelected.name">
            <div class="headerClass">
              {{ lineSelected.name }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle class="primary white--text"
          ><h3>{{ lineSelected.price_format }}</h3></v-card-subtitle
          >
          <v-card-text>
            <v-row align="center">
              <v-col class="text-center">
                <v-icon x-large>mdi-web</v-icon>
                <div class="title">{{ lineSelected.data }}</div>
                <div class="subtitle-2">Dados</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-phone-outgoing</v-icon>
                <div class="title">{{ lineSelected.voice }}</div>
                <div class="subtitle-2">Chamadas</div>
              </v-col>
              <v-col class="text-center">
                <v-icon x-large>mdi-message-text-outline</v-icon>
                <div class="title">{{ lineSelected.sms }}</div>
                <div class="subtitle-2">Mensagens</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  outlined
                  required
                  label="DDD"
                  v-model="lineSelected.code"
                  :items="ddd.data"
                  item-text="code"
                  item-value="code"
                  readonly
                >
                  <template v-slot:selection="data">
                    {{ data.item.code }} - {{ data.item.region }}
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content
                        v-text="data.item"
                      ></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.code"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.region"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  dense
                  outlined
                  required
                  :label="'Selecione a Pessoa'"
                  v-model="lineSelected.person"
                  :items="persons.data"
                  item-text="nome_completo"
                  item-value="id_pessoa"
                  v-if="!loading"
                  readonly
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" align="center" v-if="lineSelected.description">
                <v-btn
                  text
                  @click="showInfo(lineSelected)"
                  color="primary"
                  small
                >
                  Mais informações
                  <v-icon>{{
                      show === lineSelected
                        ? "mdi-chevron-up"
                        : "mdi-chevron-down"
                    }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show === lineSelected">
              <v-divider></v-divider>

              <v-card-text>
                <div v-html="lineSelected.description"></div>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CustomSelectPackageComponent from "@/components/forms/CustomSelectPackageComponent.vue";

export default {
  components: { CustomSelectPackageComponent },
  props: ["revision"],
  data() {
    return {
      revisionContent: [],
      loading: true,
      show: false,
      typeComponent: ''
    };
  },

  async mounted() {
    await this.loadPlans().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadDDD().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    await this.loadPersons().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });

    if (this.revision["activate_new_line"]) {
      this.typeComponent = this.wizard.model['activate_new_line'].type;
      this.revisionContent = this.revision["activate_new_line"];
    }
    this.loading = false;
  },
  computed: {
    ...mapState("plans", ["plans", "ddd"]),
    ...mapState("persons", ["persons"]),
    ...mapState("forms", ["wizard"]),
    ...mapState("icon", ["icons"])
  },
  methods: {
    ...mapActions("plans", ["loadPlans", "loadDDD"]),
    ...mapActions("persons", ["loadPersons"]),

    showInfo(plan) {
      this.show = this.show ? false : plan;
    }
  }
};
</script>

<style scoped></style>
