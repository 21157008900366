<template>
    <v-row class="mx-auto">
        <v-col cols="12">
            <v-card outlined :loading="loading" :disabled="loading">
                <v-card-title class="subtitle-1">
                    Desativar serviços
                </v-card-title>
                <v-card-text>
                    <v-btn color="primary" @click="clickAddNewLine()">Adicionar linha</v-btn>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col
            md="6"
            sm="12"
            v-for="(lineSelected, index) in lineSelect"
            :key="index"
        >
            <v-card outlined class="mx-auto">
              <v-card-title class="primary white--text">
                <div class="headerClass">
                  {{lineSelected.line.ddi ? lineSelected.line.ddi+' ('+lineSelected.line.ddd+') '+lineSelected.line.nr_linha : 'Selecione uma linha' }}
                </div>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeLine(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="mb-n5">
                            <v-autocomplete
                                dense
                                outlined
                                required
                                label="Linha"
                                v-model="lineSelected.line"
                                :items="lines.data"
                                item-text="nr_linha"
                                item-value="id"
                                :rules="lineRules"
                                :filter="customFilter"
                                return-object
                                @input="updateSelectedLine($event, lineSelected, index)"
                            >
                                <template v-slot:selection="data">
                                    {{ data.item.ddi }} ({{ data.item.ddd }})
                                    {{ data.item.nr_linha }} -
                                    {{ data.item.nome_completo }}
                                  {{ data.item.cod_conta ? ' - ' + data.item.cod_conta : '' }}
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.ddi }} ({{data.item.ddd}}){{ data.item.nr_linha }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                v-html="data.item.nome_completo"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="mx-auto">
                            <v-alert
                                border="left"
                                class="text-center"
                                dense
                                type="warning"
                                v-if="show === lineSelected"
                                dark
                                >Carregando pacotes da linha!
                            </v-alert>
                            <template v-if="lineSelected.packages.length">
                                <v-alert
                                    border="left"
                                    color="indigo"
                                    dark
                                    dense
                                    class="mb-0"
                                >
                                    Selecione os pacotes que deseja desativar:
                                </v-alert>
                                <v-list>
                                    <template
                                        v-for="(linePackage,
                                        index) in lineSelected.packages"
                                    >
                                        <v-list-item :key="linePackage.nome">
                                            <v-list-item-content>
                                                <v-row class="align-center" dense>
                                                    <v-col md="10">
                                                        {{ linePackage.nome }}
                                                    </v-col>
                                                    <v-col>
                                                        <v-switch
                                                            class="mt-0"
                                                            dense
                                                            v-model="linePackage.desativar"
                                                            hide-details
                                                            inset
                                                            @change="updateValues()"
                                                        ></v-switch>
                                                    </v-col>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider
                                            v-if="index < lineSelected.packages.length - 1"
                                            :key="index"
                                        ></v-divider>
                                    </template>
                                </v-list>
                            </template>
                          <template v-if="lineSelected.line.id_linha && lineSelected.packages.length < 1 && !loadingLinePackages[index].value">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Não possui
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  props: ['index'],
  data() {
      return {
          lineSelect: [],
          loading: true,
          show: false,
          lineRules: [(v) => !!v || "Linha é obrigatório"],
          loadingLinePackages: [{
            value: false
          }],
      };
  },
  async mounted() {
      await this.loadLines().catch((error) => {
          this.$swal("Oops...", error.message, "error");
      });
      this.loading = false;
  },
  computed: {
      ...mapState("packages", ["lines"]),
      ...mapGetters({
          linePackages: "packages/getLinePackages",
      }),
  },
  methods: {
      ...mapActions("packages", ["loadLines", "loadLinePackages"]),
      ...mapMutations({
          setModel: "forms/setModelRemovePackages",
      }),
      async clickAddNewLine() {
          const newLine = {
              packages: [],
              line: {},
          };

          if (
              this.lines != null &&
              Array.isArray(this.lines.data) &&
              this.lines.data.length === 1
          ) {
              newLine.line = this.lines.data[0];
              this.updateSelectedLine(this.lines.data[0], newLine);
          }

          this.loadingLinePackages.push({ value: false });

          this.lineSelect = [...this.lineSelect, newLine];
      },
      async updateSelectedLine(line, lineSelected, index) {
          this.show = this.show ? false : lineSelected;
          this.loadingLinePackages[index].value = true;
          if (line.id_linha.length) {
              lineSelected.packages = [];
              await this.loadLinePackages({ id_linha: line.id_linha }).catch(
                  (error) => {
                      this.$swal("Oops...", error.message, "error");
                  }
              );

              lineSelected.packages = this.linePackages.data;
              lineSelected.packages = lineSelected.packages.map((item) => {
                  item.desativar = false;
                  return item;
              });
          }
          this.updateValues();
          this.show = false;
          this.loadingLinePackages[index].value = false;
      },
      updateValues() {
        const payload = {
          step: this.index,
          selected: {
            model: {
              lines: this.lineSelect
            },
            revision: this.lineSelect
          },
        };
          this.setModel(payload);
      },
      closeLine(index) {
          if (index > -1) {
              this.lineSelect.splice(index, 1);
          }
          this.loadingLinePackages.splice(index, 1);
      },
      customFilter(item, queryText) {
          const textOne = item.nr_linha.toLowerCase();
          const textTwo = item.nome_completo.toLowerCase();
          const searchText = queryText.toLowerCase();

          return (
              textOne.indexOf(searchText) > -1 ||
              textTwo.indexOf(searchText) > -1
          );
      },
  },
};
</script>
<style scoped>
.headerClass {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
